import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
      Accept:         'application/ld+json',
      'Content-Type': 'application/json',
  }
});

export default {

  getDealerboardStats: async function(data) {
    try {
      let filters = null;
      const encodedFilters = encodeURIComponent(filters);
      const url = '/dealerplatform/stats/dealerboard/'
      const urlWithParams = filters ? `${url}/${encodedFilters}` : url;

      const response = await instance.get(urlWithParams, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getCustomerboardStats: async function(data) {
    try {
      let filters = null;

      if(data.filter) {
        filters = '?filter=' + encodeURIComponent(data.filter);
        filters += '&id=' + encodeURIComponent(data.id);
      }

      const url = '/dealerplatform/stats/customerboard'
      //const urlWithParams = filters ? `${url}/${filters}` : ${url};
      const urlWithParams = `${url}/${filters}`;

      const response = await instance.get(urlWithParams, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },
}
